import React from 'react'
import Link from 'next/link'
import { useSession } from 'next-auth/react'
import {
	SpeakerphoneIcon,
	ViewBoardsIcon,
	CurrencyDollarIcon,
	HeartIcon,
	ThumbUpIcon,
	SparklesIcon,
} from '@heroicons/react/outline'

const features = [
	{
		name: 'Listen User Feedback',
		description:
			'Simple and effective way to collect user feedback helps you to understand real user needs and pain points.',
		icon: SpeakerphoneIcon,
	},
	{
		name: 'Organize Product Features',
		description: 'Organize your product features in Under Consideration, Planned, In-Progress and Launched tabs.',
		icon: ViewBoardsIcon,
	},
	{
		name: 'Create Public Roadmaps',
		description:
			'Simple way to let your users know when you plan, work and ship features. You can also have private roadmaps.',
		icon: SparklesIcon,
	},
	{
		name: 'Vote Existing Ideas',
		description:
			'Users can browse and vote on existing ideas. A sinmple yet effective way to understand how strong your users needs.',
		icon: ThumbUpIcon,
	},
	{
		name: '$0/mo - Free',
		description:
			'Featuristik is free until you are growing. We only want to cover our costs and will charge makers who are scaled and have more custom support and needs.',
		icon: CurrencyDollarIcon,
	},
	{
		name: 'Made for all Makers',
		description: 'Created by an indie maker for all makers!',
		icon: HeartIcon,
	},
]

const Home = () => {
	const { data: session } = useSession()
	return (
		<div style={{ marginBottom: 32 }}>
			{/* Hero Section */}
			<div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
				<div
					className={`bg-blue-500 dark:bg-opacity-40 rounded-lg shadow-xl overflow-hidden
						lg:grid lg:grid-cols-2 lg:gap-4`}
				>
					<div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
						<div className="lg:self-center">
							<h2 className="text-3xl font-extrabold text-white sm:text-4xl">
								<span className="block">Public Product Roadmaps</span>
								<span className="block">for Indie Makers</span>
							</h2>
							<p className="mt-4 text-lg leading-6 text-blue-200">
								Collect feature requests from your users, plan and publicize your product roadmap.
							</p>
							<Link href={session ? '/dashboard' : '/auth/signin'}>
								<a
									className={`mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3
										inline-flex items-center text-base font-medium text-blue-600 hover:bg-blue-50`}
								>
									Get started for free
								</a>
							</Link>
						</div>
					</div>
					<div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
						<img
							className={`transform translate-x-6 translate-y-6 rounded-md
								object-cover object-left-top sm:translate-x-16 lg:translate-y-20`}
							src="https://ik.imagekit.io/featuristik/public-roadmap_MTkEa2l1u.jpg"
							alt="Public Roadmap Screenshot"
						/>
					</div>
				</div>
			</div>

			{/* Features Section */}
			<div className="max-w-7xl mx-auto my-10 px-4 sm:px-6 lg:px-8">
				<div className="lg:text-center">
					<h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Features</h2>
					<p
						className={`mt-2 text-3xl leading-8 font-extrabold tracking-tight
							text-gray-900 dark:text-gray-200 sm:text-4xl`}
					>
						A better way get user involved in the product roadmap.
					</p>
					{/* <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
						Lorem...
					</p> */}
				</div>

				<div className="mt-16">
					<dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
						{features.map((feature) => (
							<div key={feature.name} className="relative">
								<dt>
									<div
										className={`absolute flex items-center justify-center h-12 w-12 rounded-md
											bg-blue-500 dark:bg-opacity-40 text-white`}
									>
										<feature.icon className="h-6 w-6" aria-hidden="true" />
									</div>
									<p className="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">
										{feature.name}
									</p>
								</dt>
								<dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
							</div>
						))}
					</dl>
				</div>
			</div>

			{/* Bottom CTA Section */}
			<div
				className={`max-w-7xl mx-4 lg:mx-auto mt-20 text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8
					bg-blue-500 dark:bg-opacity-40 rounded-lg shadow-xl`}
			>
				<h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
					<span className="block">Ready to dive in?</span>
					<span className="block">Start creating your roadmap with your users today.</span>
				</h2>
				<div className="mt-8 flex justify-center">
					<div className="inline-flex rounded-md shadow">
						<Link href={session ? '/dashboard' : '/auth/signin'}>
							<a
								className={`mt-4 bg-white border border-transparent rounded-md shadow px-5 py-3
									inline-flex items-center text-base font-medium text-blue-600 hover:bg-blue-50`}
							>
								Get started for free
							</a>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Home
